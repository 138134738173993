/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

            $('.close-popup').on('click', function(e) {
              e.preventDefault();
              $('.pop-up').fadeOut('fast');
            });

            // Toggle Menu
            $('.hamburger-menu').on('click', function() {
                $('.bar').toggleClass('animate');
                $('#menu').toggleClass('active');
                $('body').toggleClass('disabled');
            });

            // Smooth Scroll Anchors
            $('.page-anchor').click(function(){
                $('html, body').animate({
                    scrollTop: $( $.attr(this, 'href') ).offset().top
                }, 800);
                return false;
            });

            // Scroll Reveal
            window.sr = ScrollReveal({
                //reset: true,
                opacity: 0,
                scale: 1,
                duration: 1000,
                distance: '100px',
                mobile: false,
                viewFactor: 0.4
            });
            sr.reveal('.fadein');


            // Single Products Pages
            $('.share').click(function(e){
                e.preventDefault();
                $('.share-this').toggleClass('active');
            });
            $('.close-share').click(function(){
                $('.share-this').toggleClass('active');
            });

            $('#signin').click(function(){
                $(this).fadeOut('fast');
                setTimeout(function() {
                    $('.signin-form').fadeIn();
                }, 600);

            });

            $('.full-desc').hide();

            $('.readmore').click(function() {
                $('.small-desc').hide();
                $('.full-desc').show();
            });

            $('.nutrition-toggle').click(function() {
                $('.nutrition-facts').addClass('active');
                $('.product-information').addClass('inactive');
            });
            $('.close-facts').click(function() {
                $('.nutrition-facts').removeClass('active');
                $('.product-information').removeClass('inactive');
            });

            var $status = $('.pagingInfo');
            var $slickElement = $('.marukan-slider');

            $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)

            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + '/' + slick.slideCount);
            });

            // Marukan Slider
            $(".marukan-slider").slick({
                autoplay: true,
                dots: false,
                // fade: true,
                autoplaySpeed: 20000,
                speed: 400,
                infinite: true,
                pauseOnHover: false,
                appendArrows: $('.arrows'),
                responsive: [
                {
                breakpoint: 480,
                settings: {
                    dots: false,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
                }]
            });


            $('.selectpicker').on('change', function() {
                // console.log( this.value );
                $(this).closest('form').trigger('submit');



                if($(this).val() == 'all-products'){
                    location.href = "shop";
                }

            });



            $('.selectpicker').selectpicker({
                style: 'btn-red-o'
            });


            // Intro Slider
            $(".process-slider").slick({
                autoplay: false,
                pauseOnHover: false,
                dots: false,
                autoplaySpeed: 15000,
                speed: 400,
                cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                prevArrow: '<div class="prev slider-nav"></div>',
                nextArrow: '<div class="next slider-nav"></div>',
            });






      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        $( document ).ready(function() {
          setTimeout(function() {
              $('.pop-up').addClass('active');
          }, 600);
        });

        var $status = $('.pagingInfo');
        var $slickElement = $('.intro-slider');

        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + '/' + slick.slideCount);
        });


        // Intro Slider
        $(".intro-slider").slick({
            autoplay: true,
            pauseOnHover: false,
            dots: false,
            autoplaySpeed: 15000,
            speed: 400,
            appendArrows: $('.arrows'),
            responsive: [{
            breakpoint: 480,
            settings: {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }]
        });



        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    // Shop Now
    'shop': {

      init: function() {

            $('.isotope').show();

            $('#CA').click(function() {
                $('.state-province').html('ALL PROVINCES');
                $('.usa-retailers-dropdown').hide();
                $('.canada-retailers-dropdown').show()
                $('.states-dropdown').css("visibility","visible");
                $('.filtername').html('ALL PROVINCES');
            });

            $('#US').click(function() {
                $('.state-province').html('ALL STATES');
                $('.usa-retailers-dropdown').show();
                $('.canada-retailers-dropdown').hide();
                $('.states-dropdown').css("visibility","visible");
                $('.filtername').html('ALL STATES');
            });

            /*
            $('#US').click(function() {
                $('.retailer.canada').removeClass('united-states');
            });
            */

            // Recipes Filter
            $(function() {
                  // init Isotope
                    // init Isotope
                    var $container = $('.isotope').isotope({
                        itemSelector: '.recipe-filter',
                        percentPosition: true,
                        // layoutMode: 'fitRows'
                    });
                    // layout Isotope after each image loads
                        $container.imagesLoaded().progress( function() {
                        $container.isotope('layout');
                    });

                    // bind filter button click
                    $('#catfilters').on( 'click', '.btn', function() {


                    var filterName = $(this).attr('data-name');
                    $('.countryfiltername').html(filterName);

                    var filterValue = $( this ).attr('data-filter');
                    $container.isotope({ filter: filterValue });
                    });

                  // bind filter button click
                  $('#filters').on( 'click', '.btn', function() {

                    var filterName = $(this).attr('data-name');
                    $('.filtername').html(filterName);

                    var filterValue = $( this ).attr('data-filter');
                    $container.isotope({ filter: filterValue });
                  });

                  // change is-checked class on buttons
                  $('.button-group').each( function( i, buttonGroup ) {
                    var $buttonGroup = $( buttonGroup );
                    $buttonGroup.on( 'click', 'button', function() {
                      $buttonGroup.find('.is-checked').removeClass('is-checked');
                      $( this ).addClass('is-checked');
                    });
                  });
            });

      }
    },



    // Single Consumer Product
    'single_consumer': {
      init: function() {

          $('.menu-item-728').addClass('active');

      }
    },

    // Tax Consumer Product
    'tax_product_cat': {
      init: function() {

          $('.menu-item-728').addClass('active');
      }
    },

    // Single Industrial Product
    'single_industrial_products': {
      init: function() {

          $('.menu-item-833').addClass('active');

      }
    },

    // Tax Industrial Product
    'tax_industrial_cat': {
      init: function() {

          $('.menu-item-833').addClass('active');
      }
    },

    // Single Recipe
    'single_marukan_recipes': {
      init: function() {

        $('.menu-item-834').addClass('active');

      }
    },
    // Single Recipe
    'single_product_page': {
      init: function() {


        $(function() {
          var top = $('#sidebar').offset().top - parseFloat($('#sidebar').css('marginTop').replace(/auto/, 0));
          var footTop = $('.bottom-section').offset().top - parseFloat($('.bottom-section').css('marginTop').replace(/auto/, 0));

          var maxY = footTop - $('#sidebar').outerHeight();

          $(window).scroll(function(evt) {
            var y = $(this).scrollTop();
            if (y > top) {
              if (y < maxY) {
                $('#sidebar').addClass('fixed').removeAttr('style');
              } else {
                $('#sidebar').removeClass('fixed').css({
                  position: 'absolute',
                  top: (maxY - top) + 'px'
                });
              }
            } else {
              $('#sidebar').removeClass('fixed');
            }
          });
        });


      }
    },

    // Heritage
    'our_heritage': {
      init: function() {

        // Intro Slider
        $(".years-slider").slick({
            // autoplay: true,
            dots: false,
            slidesToShow: 8,
            slidesToScroll: 8,
            infinite: false,
            appendArrows: $('.arrows'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        dots: false,
                        arrows: false,
                        infinite: false,
                        slidesToShow: 6,
                        slidesToScroll: 6
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: false,
                        arrows: false,
                        infinite: false,
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                }
            ]
        });

        $('.timeline-sec').waypoint(function(direction) {
          if (direction === 'down') {
            $(this).find('.section-title h2').addClass('underline');
            $(this).addClass('inview');
          } else {
            $(this).removeClass('inview');
            $(this).find('.section-title h2').removeClass('underline');
          }
        }, {
          offset: '200px'
        });

        $('.content-info').waypoint(function(direction) {
          if (direction === 'down') {
            $('.timeline-nav-wrap').removeClass('fixed');
          } else {
            $('.timeline-nav-wrap').addClass('fixed');
            $('.banner').addClass('fixed');
          }
        }, {
            offset: '100%'
        });

        // Smooth Scroll Anchors
        /*
        $('.scroll-anchor').click(function(){
            var scrollContainer = $('body');
            scrollContainer.animate({
              scrollTop: $( $.attr(this, 'href') ).offset().top - 155
            }, 800);
            return false;
        });
        */

        $('.year-anchor').click(function(){
    	    $('html, body').animate({
  	        scrollTop: $( $.attr(this, 'href') ).offset().top -150
    	    }, 500);
    	    return false;
      	});



      }
    },

    // Products page
    'post_type_archive_marukan_recipes': {
      init: function() {

        $('.isotope').show();

        $(function(){
            var $container = $('.isotope'),
            filters = {};

            $container.isotope({
                itemSelector : '.recipe-filter'
            });

            $('.filter a').click(function(){
                $(this).parents('.dropdown').removeClass('open');

                var $this = $(this);
                // don't proceed if already selected
                if ( $this.hasClass('selected') ) {
                return;
                }

                var $optionSet = $this.parents('.option-set');
                // change selected class
                $optionSet.find('.selected').removeClass('selected');
                $this.addClass('selected');

                // store filter value in object
                // i.e. filters.color = 'red'
                var group = $optionSet.attr('data-filter-group');
                filters[ group ] = $this.attr('data-filter-value');
                // convert object into array
                var isoFilters = [];
                for ( var prop in filters ) {
                isoFilters.push( filters[ prop ] )
                }
                var selector = isoFilters.join('');
                $container.isotope({ filter: selector });

                return false;

            });
        });

        // Add Name to Recipe Search

        $('.recipeCat').click(function(){
            var filterName = $(this).attr('data-name');
            $('#recipeCat').html('Searching for ' + filterName);
        });
        $('.productCat').click(function(){
            var filterNameProduct = $(this).attr('data-name');
            $('#productCat').html('recipes using ' + filterNameProduct);
        });
           $('.ingredientCat').click(function(){
            var filterIng = $(this).attr('data-name');
            $('#ingredientCat').html('ingredients using ' + filterIng);
        });

        $('.showAll').click(function(){
            var filterNameProduct = $(this).attr('data-name');
            $('#recipeCat').html('Showing all');
        });

        $('.showAllproducts').click(function(){
            var filterNameProduct = $(this).attr('data-name');
            $('#productCat').html('');
        });
        $('.showAllingredients').click(function(){
            var filterNameProduct = $(this).attr('data-name');
            $('#ingredientCat').html('');
        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
